<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="用户信息" left-arrow @click-left="$router.back()" />
    <van-cell-group>
     

      <van-field name="uploader" label="用户头像">
        <template #input>
          <van-uploader
            v-model="fileList"
            multiple
            :max-count="1"
            :after-read="afterReadAudio"
          />
        </template>
      </van-field>
    </van-cell-group>
    <div class="btn-box">
      <van-button
        type="info"
        size="large"
        round
        block
        @click="UpdateUserInfoHanlder"
        >确认提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { getUserInfo,UpdateUserInfo } from '@/api/user'
import { uploadfile } from "@/api/file";
import { Toast } from "vant";

export default {
  name: "user-changeuserInfo",
  data() {
    return {
      userInfo: {},


      errMes: {
        headImgurl: "",
      },
      fileList: [
        // {
        //   url: 'https://img01.yzcdn.cn/vant/tree.jpg',
        //   imageFit: 'contain',
        //   previewSize: 120,
        // },
      ],
    };
  },
   created () {
    this.getUser();
   
  },
  methods: {
    async getUser () {

      var aresult=await getUserInfo();
      this.userInfo = aresult.data.data;

     if(this.userInfo.headImgurl!="" && this.userInfo.headImgurl!=undefined){
       this.fileList.push({
        url: this.userInfo.headImgurl,

        imageFit: "contain",
        previewSize: 80,
      });
     }
    },
    checkheadImgurl() {
      if (!this.userInfo.headImgurl) {
        this.errMes.headImgurl = "请输入详细描述";
        return false;
      }
      this.errMes.headImgurl = "";
      return true;
    },
    // 保存用户编辑
    async UpdateUserInfoHanlder() {
      try {
        if (!this.checkheadImgurl()) {
          return false;
        }

        const data = await UpdateUserInfo(this.userInfo);
    
        if (data.data.success) {
          this.$router.back();
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
        Toast("保存失败：" + error);
      }
    },
    afterReadAudio: async function (files) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfile(formData);

      let url = aresult.data; // Get url from response
      this.userInfo.headImgurl = url;

      this.fileList = [];
      this.fileList.push({
        url: url,

        imageFit: "contain",
        previewSize: 80,
      });

      Toast("文件上传成功");
    },
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
