import request from '@/utils/request'
export function login (data) {
  return request({
    url: '/api/Login/account',
    data,
    method: 'post'
  })
}

export function loginMock (data) {
  return request({
    url: '/api/Login/accountMock',
    data,
    method: 'post'
  })
}

export function LoginByWX (data) {
  return request({
    url: '/api/Login/LoginByWX',
    data,
    method: 'post'
  })
}
export function LoginByWXForA3 (data) {
  return request({
    url: '/api/Login/LoginByWXForA3',
    data,
    method: 'post'
  })
}

export function GetWeixinOpenId (code) {
  return request({
    url: '/api/Login/GetWeixinOpenID?code='+code,
   
  })
}
export function LoginByWXmini (data) {
  return request({
    url: '/api/Login/LoginByWXmini?openid='+data,
    method: 'post'
  })
}


export function userChangePassWord (data) {
  return request({
    url: '/api/User/ChangePassWord',
    data,
    method: 'post'
  })
}
export function ChangePassWord (data) {
  return request({
    url: '/api/User/ChangePassWord',
    data,
    method: 'post'
  })
}



export function UpdateUserInfo(params){
  return request({
    url: '/api/User/Update',
    data: {...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}

// 获取用户个人信息
export function getUserInfo () {
  return request({
    url: '/api/User/Get'
  })
}
//是否有某一角色
export function HasRole(role){
  return request({
    url: `/api/User/HasRole?role=${role}`
  })
}


// 
export function getUserName(id){
  return request({
    url: `/api/User/GetName?id=${id}`
  })
}
//检查用户信息
export function checkUser () {
  return request({
    url: '/api/User/CheckUser'
  })
}
//找回密码 发送手机验证码
export function resetPassWordSMSCode (phone) {
  return request({
    url: `/api/User/ResetPassWordSMSCode?phone=${phone}`
  })
}
//根据手机验证码 修改密码
export function resetPassWordByPhone(params){
  return request({
    url: '/api/User/ResetPassWordByPhone',
    data: {...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
    
  })
}


//验证用户 发送手机验证码 
export function checkUserLoginSMSCode () {
  return request({
    url: `/api/User/CheckUserLoginSMSCode`
  })
}

//验证验证码
export function checkUserLoginCode (code) {
  return request({
    url: `/api/User/CheckUserLoginCode?code=${code}`
  })
}

//用户年度学习时长历史
export function GetUserLearnYear () {
  return request({
    url: '/api/User/GetUserLearnYear'
  })
}

export function GetUserWelcome () {
  return request({
    url: '/api/User/GetWelcome'
  })
}

// 获取用户个人资料
export function getUserProfile () {
  return request({
    url: '/user/profile' // 获取用户个人资料的接口地址
  })
}

// 编辑用户头像的API
export function updateImg (data) {
  return request({
    url: '/user/photo', // 编辑头像的地址
    method: 'patch', // 设置头像的类型
    data
  })
}

// 保存用户的方法
export function saveUserInfo (data) {
  return request({
    url: '/user/profile', // 编辑资料的地址
    data,
    method: 'patch'
  })
}

// 获取用户线上线下教学时长数据
export function getUserLearn() {
    return request({
        url: '/api/User/GetUserLearn', // 编辑资料的地址
        method: 'get'
    })
}

// 获取用户个人信息
export function getUserHomePage (id) {
  return request({
    url: '/api/User/GetUserHomePage?id='+id
  })
}

//关注某人
export function addUserFollower (id) {
  return request({
    url: '/api/User/AddUserFollower?userid='+id
  })
}


// 取消关注某人
export function cancelUserFollower (id) {
  return request({
    url: '/api/User/CancelUserFollower?userid='+id
  })
}
